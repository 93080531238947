import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import Button from '../../components/atoms/inputs/Button';

const defaultOptionForCommunityFilter = {
  db_ref: 'all',
  name: 'All',
};

const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image:
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: [
    'sense of belonging',
    'community ai',
    'Grand Rapids Gold',
    'buckets',
    'hidden gems',
    'community app',
    'flockx',
  ].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'Find Your Flock',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'sense of belonging',
  'community ai',
  'Grand Rapids Gold',
  'buckets',
  'community app',
  'hidden gems',
  'nomadic lifestyle',
  'friend finder',
  'flockx',
  'real-time',
];

export default function HomePage({
  data: {
    site: {
      siteMetadata: { description, siteUrl, title },
    },
  },
}) {
  const schemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
  };

  const handleCTAButtonClick = () => {
    window?.gtag('event', 'grgold_landing_cta_click', {
      event_category: 'CTA',
      event_label: 'Gold Landing CTA',
    });

    window.location.href = 'https://community.flockx.io/communities/dc9704cb-5b43-4e4a-8760-928303391ef5';
  };

  return (
    <BaseLayout
      mainSectionClass="relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass="fixed w-full z-50 bg-transparent"
    >
      <SEO
        title="Experience Grand Rapids Gold Like Never Before"
        keywords={SEO_KEYWORDS}
        description="Get closer to the action with Grand Rapids Gold! Connect with fans, chat with Buckets AI, win prizes, and elevate your game-day experience."
        schemaMarkup={schemaMarkup}
      />

<div className="relative h-screen">
      {/* Background with class for media query handling */}
      <div className="hero-background gr-gold" />

      {/* Content container with existing Tailwind classes */}
      <div className="absolute inset-0 z-10 flex pt-24">
        <div className="w-full flex">
          <div className="w-full px-2 lg:px-6 md:pl-24 lg:pl-32 
            flex items-end md:items-center 
            pb-[15vh] md:pb-0"
          >
            <div className="w-full mx-auto md:mx-0 
              max-w-xl md:max-w-2xl 2xl:max-w-4xl
              text-center md:text-left"
            >
              <h1 className="text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl 
                font-bold tracking-regular text-grey-400 lg:text-white
                mb-4 md:mb-6"
              >
               Welcome to the GR Gold Community
              </h1>
              
              <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl 
                font-regular text-grey-400 lg:text-white
                mb-6 md:mb-8 
                leading-7 md:leading-8 lg:leading-9"
              >
                Get closer to the action with the Grand Rapids Gold community. Connect with local fans, enjoy exclusive prize draws, chat with Buckets AI, and even upgrade your seat for the ultimate game-day experience.
                </p>

              <div>
                <Button
                  label="Connect with Buckets"
                  onClickHandler={handleCTAButtonClick}
                  id="Connect with Buckets"
                  className="!bg-primary hover:!bg-primary/90 !from-primary !to-primary !px-16"
                  isSecondaryButton={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    </BaseLayout>
  );
}

export const query = graphql`
  query ActivityHomePageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`;